import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Space, Avatar, List, Layout } from 'antd';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Modal from 'antd/es/modal/Modal';
// import { List } from 'antd/es/form/Form';
const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
	<Space>
	  {React.createElement(icon)}
	  	{text}
	</Space>
);

function App() {
	const [articleList, setArticleList] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [content, setContent] = useState('');
	const fetchArticleList = async () => {
		fetch('https://elasticnotes.com/api/article/getLists', {
			method: 'GET',
		}).then(response => {
			return response.json();
		}).then(res => {
			if (res.data.length > 0) {
				setArticleList(res.data);
			}
		});
	};

	const showModal = (params) => {
		const reqFormData = new FormData();
        reqFormData.append('id', params);
		fetch('https://elasticnotes.com/api/article/detail', {
			method: 'POST',
			body: reqFormData,
		}).then(response => {
			return response.json();
		}).then(res => {
			setContent(res.data.content);
		});
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		fetchArticleList();
	}, [])
	console.log(articleList)

	return (
		<>
			<Layout>
				<Header
					style={{zIndex: '100', background: '#ffffff', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)'}}
				>
					<Fragment>
						<svg style={{marginTop: '18px'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-file-earmark-richtext" viewBox="0 0 16 16">
							<path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
							<path d="M4.5 12.5A.5.5 0 0 1 5 12h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm0-2A.5.5 0 0 1 5 10h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm1.639-3.708 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V8.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V8s1.54-1.274 1.639-1.208zM6.25 6a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z"/>
						</svg>
						<span style={{fontSize: '20px', position: 'relative', bottom: '6px', left: '5px'}}><strong>弹力笔记</strong></span>
					</Fragment>
				</Header>
				<Content
					style={{background: '#ffffff'}}
				>
					<Row>
						<Col span={18} offset={3}>
							<List
								itemLayout="vertical"
								size="large"
								pagination={{
									onChange: (page) => {
										console.log(page);
									},
									pageSize: 5,
								}}
								dataSource={articleList}
								footer={
									<div>
										{/* <b>ant design</b> footer part */}
									</div>
								}
								renderItem={(item) => (
									<List.Item
										key={item.title}
										actions={[
											<IconText icon={StarOutlined} text={item.like} key="list-vertical-star-o" />,
											<IconText icon={LikeOutlined} text={item.start} key="list-vertical-like-o" />,
											<IconText icon={MessageOutlined} text={item.comment} key="list-vertical-message" />,
										]}
										extra={
											<img
												width={272}
												alt="logo"
												src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
											/>
										}
									>
									<List.Item.Meta
										avatar={<Avatar src={item.head} />}
										// eslint-disable-next-line
										title={<a onClick={(e) => showModal(item.id)}>{item.title}</a>}
										description={item.desc}
									/>
										{item.content}
									</List.Item>
								)}
							/>
						</Col>
					</Row>
				</Content>
				<Footer>
					<Row
						align="middle"
						justify="center"
					>
						{/* <div>
							Copyright © 2022 弹力笔记 All rights reserved.
						</div> */}
						<div>
							<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051070" style={{textDecoration: 'none', color: '#939393'}} target="_black">京公网安备11010502051070号</a>&nbsp;&nbsp;&nbsp;
							<a href="https://beian.miit.gov.cn/" style={{textDecoration: 'none', color: '#939393'}} target="_black">京ICP备18011845号-6</a>
						</div>
					</Row>
				</Footer>
			</Layout>
			<Modal
				open={isModalOpen}
				onCancel={handleCancel}
				footer={null}
				width={'80%'}
			>
				<div dangerouslySetInnerHTML={{__html: content}}></div>
			</Modal>
		</>
	);
}

export default App;
